<template>
  <app-layout class="bg-background">
    <div class="container mx-auto">
      <table
        class="
          mt-10
          min-w-full
          divide-y divide-divider-gray
          border-b border-divider-gray
          bg-white
        "
      >
        <tr>
          <th class="font-bold">{{ $t("admin.cron.name") }}</th>
          <th class="font-bold">{{ $t("admin.cron.action") }}</th>
        </tr>
        <tr v-for="slotData in data.data" :key="slotData.id">
          <td width="90%">
            <div class="flex flex-inline items-center">
              {{ slotData.name }} &nbsp;
              <app-form-calendar-range
                v-if="slotData.range_date != null"
                class="mb-2"
                :key="slotData.id"
                v-model="slotData.range_date"
              />
            </div>
          </td>
          <td width="10%">
            <app-button-outline
              class="bg-white"
              padding="px-3 py-1"
              :loading="loading && slotData.id === indexClicked"
              @click="cronItemClicked(slotData)"
            >
              <app-icon-outline
                v-if="!(loading && slotData.id === indexClicked)"
                name="TerminalIcon"
                class="h-5 w-5 mr-3 text-gray-400"
              />
              <p class="text-xs">
                {{ $t("admin.cron.run_cron") }}
              </p>
            </app-button-outline>
          </td>
        </tr>
      </table>
    </div>
  </app-layout>
</template>

<script>
export default {
  data() {
    return {
      indexClicked: null,
    };
  },
  computed: {
    data() {
      return {
        data: [
          {
            id: 1,
            name: this.$t("admin.cron.payout"),
            range_date: null,
          },
          {
            id: 2,
            name: this.$t("admin.cron.settlement"),
            range_date: null,
          },
          {
            id: 4,
            name: this.$t("admin.cron.payment_profit"),
            range_date: {
              start_date: this.$moment().startOf("month").format("YYYY-MM-DD"),
              end_date: this.$moment().format("YYYY-MM-DD"),
            },
          },
        ],
      };
    },
    loading() {
      return this.$store.getters["paymentStore/loading"];
    },
  },
  methods: {
    async cronItemClicked(model) {
      this.indexClicked = model.id;
      switch (model.name) {
        case this.$t("admin.cron.payout"):
          await this.fetchPayout();
          break;
        case this.$t("admin.cron.settlement"):
          await this.fetchSettlement();
          break;
        case this.$t("admin.cron.payment_profit"):
          await this.postPaymentProfit(model.range_date);
          break;
      }
    },
    async fetchPayout() {
      let cronPayout = await this.$store.dispatch("paymentStore/cronPayout");
      if (this.$lodash.isEmpty(cronPayout.errors)) {
        this.$notify(
          {
            group: "success",
            title: this.$t("message.send_successful"),
          },
          8000
        );
      }
    },
    async fetchSettlement() {
      let cronSettlement = await this.$store.dispatch(
        "paymentStore/cronSettlement"
      );

      if (this.$lodash.isEmpty(cronSettlement.errors)) {
        this.$notify(
          {
            group: "success",
            title: this.$t("message.send_successful"),
          },
          8000
        );
      }
    },
    async postPaymentProfit(range_date) {
      let cronPaymentProfit = await this.$store.dispatch(
        "paymentStore/cronPaymentProfit",
        {
          start_date: range_date.start_date,
          end_date: range_date.end_date,
        }
      );

      if (!this.$lodash.isEmpty(cronPaymentProfit.errors)) {
        this.$notify(
          {
            group: "error",
            title: cronPaymentProfit.message,
          },
          8000
        );
        return;
      }

      this.$notify(
        {
          group: "success",
          title: this.$t("message.send_successful"),
        },
        8000
      );
    },
  },
};
</script>